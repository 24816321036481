<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 60px;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1
      div.d-flex.flex-column.justify-center(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") QUÉ ES
          p(style="font-size: 14px; margin-bottom: 2em;") Los clientes suelen hablar  de un restaurante en términos de  relación Calidad / precio. En consecuencia, en aquellos restaurantes donde perciben más calidad o valor están dispuestos a pagar más y viceversa.
          p(style="font-size: 14px; margin-bottom: 0.8em;") El Índice de Precios Ajustados (IPJ) es un indicador que nos permite saber si podemos subir algo los precios en base a la comparativa de la relación Calidad /precio del restaurante o de la marca con los competidores.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 60px;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1_en
      div.d-flex.flex-column.justify-center(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") WHAT DOES IT MEAN
          p(style="font-size: 14px; margin-bottom: 2em;") Customers usually talk about a restaurant in terms of quality/price ratio, understanding that in those restaurants where they receive/perceive more quality or value, it makes sense to pay something more and vice versa.
          p(style="font-size: 14px; margin-bottom: 0.8em;") The LEI is an indicator that allows us to know if we can raise prices somewhat based on the comparison of the quality/price ratio of the restaurant or the brand with the competitors.
</template>

<script>
import Card_1_SVG_1 from "./assets/Card_1_SVG_1";
import Card_1_SVG_1_en from "./assets/Card_1_SVG_1_en";

export default {
  name: "Card_1",
  components: {Card_1_SVG_1, Card_1_SVG_1_en}
}
</script>

<style scoped>

</style>
